.banner-main {
    position: relative;
    margin-top: -9px;
}

.banner-main img {
    width: 100%;
}

.text-overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.text-overlay h6 {
    color: #FFF;
    margin-bottom: 21px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    font-family: Poppins;
    line-height: normal;
}

.text-overlay h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 63px;
    font-style: normal;
    font-weight: 600;
    line-height: 70px;
    max-width: 70%;
}

.text-overlay h2 span {
    color: #B68B27;
}

.text-overlay p {
    color: #BCBCBC;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    max-width: 550px;
    margin: 30px 0 0;
}

.case-study {
    padding: 70px 0 20px;
}

.left-case h3 {
    font-size: 30px;
}

.left-case h6 {
    margin: 0 0 3px;
}

.left-case h6 img {
    margin-right: 5px;
}

.left-case a {
    margin-top: 80px;
}

.cm-btn {
    border-radius: 3px;
    background: #002B4D;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    padding: 14px 24px;
    float: left;
}

.cm-btn i {
    margin-left: 9px;
    font-size: 15px;
}

.right-case {
    padding-top: 80px;
}

.right-case .slick-list {
    padding-top: 10px;
}

.right-case button.slick-arrow {
    position: absolute;
    top: -24px;
    background: #022b4d17;
    border: 1px solid #ddd;
    width: 35px;
    height: 35px;
    border-radius: 100px;
}

.right-case button.slick-arrow.slick-prev {
    left: inherit;
    right: 60px;
}

.right-case button.slick-arrow.slick-prev::before {
    content: "";
    background-image: url("../../../assets/images/left.png");
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 7px;
    left: 7px;
}

.right-case button.slick-arrow.slick-next::before {
    content: "";
    background-image: url("../../../assets/images/left.png");
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    transform: rotate(180deg);
    top: 7px;
    left: 7px;
}

.right-case button.slick-arrow.slick-next {
    right: 12px;
}

.innder-cat {
    padding: 0 11px;
}


.sha-in-cat h5 {
    color: #022b4d;
    font-weight: 600;
    font-size: 17px;
    margin: 0;
}

.sha-in-cat p {
    color: #9e9e9e;
    font-size: 14px;
}

.sha-in-cat a.cm-btn {
    font-size: 13px;
    text-transform: uppercase;
    padding: 8px 11px;
    width: 100%;
}

.about-sec {
    padding: 80px 0;
}

.about-left img {
    width: 100%;
    padding-right: 20px;
}

.about-left a {
    float: right;
    margin: 13px 19px 0 0;
}

.about-right h3 span {
    font-size: 20px;
}

.about-right h6 img {
    margin-right: 4px;
}

.about-inner {
	border-radius: 11px;
	border: 1px solid #F1F1F1;
	background: #FAFAFA;
	padding: 10px;
	margin-bottom: 37px;
}

.about-inner h5 {
	overflow: hidden;
	color: #002B4D;
	font-feature-settings: 'clig' off, 'liga' off;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: capitalize;
	margin: 12px 0 13px;
	padding: 0 10px;
}

.about-inner ul {
	padding: 0 10px;
	display: flex;
	align-items: center;
}

.about-inner ul li {
	list-style: none;
	color: var(--Dark-75, rgba(8, 8, 8, 0.75));
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: capitalize;
	margin-right: 15px;
}

.about-inner ul li:last-child {
	margin: 0;
}

.about-inner ul li img {
	margin-right: 8px;
}

.pro-img img {
	width: 100%;
	border-radius: 10px;
	height: 200px;
	object-fit: cover;
}

.btn-butn a i {
	font-size: 13px;
	margin-left: 6px;
}

.btn-butn {
	padding: 17px 10px 19px;
	display: flex;
	justify-content: space-between;
}

.btn-butn a {
	border-radius: 10px;
	background: #002B4D;
	color: #fff;
	text-decoration: none;
	padding: 7px 20px 10px;
}

.join-esion img {
	width: 100%;
}

.inner-right-box {
	border-radius: 8px;
	background: #F3F7FB;
	padding: 19px;
	margin: 7px 0;
}

.inner-right-box h5 {
	color: #002B4D;
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
}

.inner-right-box h5 i {
	margin-right: 6px;
}

.inner-right-box span {
	color: #727272;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	margin: 0;
}

.counter ul {
	display: flex;
	justify-content: center;
	background: #022b4d;
	border-radius: 10px;
}

.counter ul h5 {
	font-size: 29px;
	color: #fff;
	font-weight: 700;
}

.counter ul span {
	color: #fff;
	font-weight: 600;
}

.counter ul h5 {
	font-size: 35px;
	color: #fff;
	font-weight: 700;
}

.counter ul li {
	width: 25%;
	border-left: 1px solid #ffffff4a;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 0;
}

.counter ul {
	display: flex;
	justify-content: center;
	background: #032C4C;
	border-radius: 10px;
	padding: 0;
	max-width: 85%;
	margin: 0 auto !important;
}

.counter {
	position: relative;
}

.counter ul li:first-child {
	border: 0;
}

.student-dec h3 {
	color: #fff;
}

.student-dec h3 span {
	font-weight: 400;
}

.student-dec p {
	color: #FFF;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	max-width: 60%;
	margin: 0 auto;
}

.student-dec {
	text-align: center;
	padding: 200px 0 90px;
	background-image: url("../../../assets/images/tmbk.png");
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: -70px;
}

.btn-list a {
	border-radius: 3px;
	background: #B68B27;
	color: #fff;
	text-decoration: none;
	font-size: 15px;
	font-weight: 600;
	padding: 17px 31px;
	margin: 0 9px;
}

.btn-list a:last-child {
	background: transparent;
	border: 1px solid;
}

.btn-list a i {
	margin-left: 9px;
}

.btn-list {
	display: flex;
	justify-content: center;
	margin: 33px 0 0;
}