.loginouter {
    background: #002B4D;
    /* height: 100vh; */
    /* padding: 50px 0; */
    display: flex;
    align-items: center;
    position: relative;
}

.loginouter.sign-up {
    height: auto;
}

.loginouter::before {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    width: 250px;
    height: 250px;
    background-image: url("../../../assets/images/loginaf.png");
    background-size: 100%;
    background-repeat: no-repeat;
    pointer-events: none;
}

.loginouter::after {
    position: absolute;
    right: 30%;
    bottom: 0;
    content: "";
    width: 250px;
    height: 170px;
    background-image: url("../../../assets/images/loginbf.png");
    background-size: 100%;
    background-repeat: no-repeat;
    pointer-events: none;
}

.login-left {
    border-radius: 20px;
    background: #FFF;
    box-shadow: -30px 30px 0 0 #ffffff14;
    padding: 45px 72px;
    position: sticky;
    top: 0;
}

.login-left h3 {
    color: var(--Light-Text-Color-Title, #11142D);
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.7px;
    text-align: center;
    margin-bottom: 21px;
}

.login-left .input-group label {
    color: var(--Light-Text-Color-Title, #11142D);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.08px;
    width: 100%;
    margin-bottom: 3px;
}

.login-left .input-group input[type="number"],
.login-left .input-group input[type="password"],
.login-left .input-group input[type="text"],
.login-left .input-group select {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    background: var(--Light-Fills-Color-Text-Field, #FFF);
    width: 100%;
    height: 55px;
    padding: 0 17px;
    outline: none;
}

.login-left .input-group {
    margin-bottom: 25px;
    position: relative;
}

.login-left .input-group span {
    position: absolute;
    top: 38px;
    right: 14px;
}

.login-left .input-group span img {
    max-width: 20px;
}

.login-left [type="submit"] {
    border-radius: 8px;
    background: #B68B27;
    height: 56px;
    width: 100%;
    border: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-top: 14px;
}

.top-lgo {
    text-align: center;
    margin-bottom: 14px;
}

.or-val span {
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.5px;
    margin: 0 auto;
    display: table;
    background: #fff;
    padding: 0 17px;
    position: relative;
    z-index: 1;
}

.or-val hr {
    margin: -7px 0 35px;
}

.logins-social a {
    width: 33.33%;
    border-radius: 5px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    margin: 0 7px;
    padding: 11px 15px;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
}

.logins-social {
    display: flex;
}

.logins-social a img {
    margin-right: 7px;
}

.login-right h3 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.473px;
}

.login-right p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 60%;
}

.login-right {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 110px;
}

.login-right a {
    border-radius: 4px;
    border: 1.5px solid #FFF;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
    padding: 14px 43px;
    position: absolute;
    top: 40px;
    right: 0;
}

.error {
    color: red;
    font-size: 13px;
    margin-bottom: 0px;
}