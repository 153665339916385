.otp div {
    justify-content: center;
}

.otp .otp-field input {
    width: 53px !important;
    height: 53px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin: 0 7px;
}

.otp {
    padding-bottom: 100px;
}

.otp-field {
    padding-bottom: 30px;
}