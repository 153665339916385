.web-header {
    background: #013056;
    position: relative;
    z-index: 1;
}

.web-header .navbar-nav {
    padding-left: 110px;
}

.op-head-bar {
    padding: 10px 0;
}

.top-barleft ul {
    display: flex;
    align-items: center;
}

.top-barleft ul li {
    color: #fff;
    list-style: none;
    margin-right: 30px;
}

.top-barright ul {
    display: flex;
    justify-content: flex-end;
}

.top-barright ul li {
    list-style: none;
    margin-left: 15px;
    color: #fff;
}

.top-barright ul li a {
    color: #fff;
}

.link-header {
    background: #09273F;
    border-radius: 80px 0 0 0;
    padding: 5px 0;
    border-bottom: 2px solid #B68B27;
}

.outer-head {
    display: flex;
    justify-content: space-between;
}

.header-nav li a {
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
}

.search-btn {
    display: flex;
    align-items: center;
}


.search {
    display: flex;
}

.search span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DEDEDE;
    border-radius: 100px;
    margin-left: 10px;
}

.login-btn {
    margin-left: 25px;
}

.login-btn a {
    border-radius: 3px;
    background: #B68B27;
    padding: 11px 19px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}