.inner-list-ft h4 {
	color: #fff;
	font-size: 21px;
	font-weight: 600;
	margin-bottom: 14px;
}

.inner-list-ft ul {
	padding: 0;
	margin: 0;
}

.inner-list-ft ul li {
	margin-bottom: 14px;
    list-style: none;
}

.inner-list-ft ul li a {
	color: #fff;
	text-decoration: none;
}


.footer {
	background: #032c4c;
	padding: 70px 0 0;
}

.foter-social ul li {
	margin-left: 12px;
	list-style: none;
}

.foter-social ul li a {
	background: #ffffff40;
	color: #fff;
	width: 40px;
	height: 40px;
	display: flex;
	border-radius: 100px;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}


.foter-social ul {
	display: flex;
	align-items: center;
	margin: 0;
	justify-content: flex-end;
}

.f-center p {
	font-size: 13px;
	color: #fff;
	margin: 0;
	text-align: center;
}
